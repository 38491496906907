 <template>
	<el-dialog top="1vh" :title="title" width="1050px" :visible.sync="this_is_show">
		<el-form class="form_ordinary" label-width="97px" style="padding-top:0">

			<div class="big_tit" style="margin:0">收款人/司机/货车</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="收款人姓名" style="width:320px">
					<el-input class="el_inner_width" v-model="truck_owner_info.name" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机姓名" style="width:320px">
					<el-input class="el_inner_width" v-model="driver_info.name" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="车牌号" style="width:320px">
					<el-input class="el_inner_width" v-model="truck_info.truck_plate_num" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="收款人电话" style="width:320px">
					<el-input class="el_inner_width" v-model="truck_owner_info.tel" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机电话" style="width:320px">
					<el-input class="el_inner_width" v-model="driver_info.tel" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货车信息" style="width:320px">
					<el-input class="el_inner_width" v-model="truck_info.truck_type_name_text" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">已付运费 (预设运费:{{cost_info.freight_total}}元)</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货主应付" style="width:388px">
					<el-input class="el_inner_width" v-model="cost_info.freight_total" disabled>
						<el-button type="success" slot="append" style="width:98px">元</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货主已付" style="width:588px">
					<el-input class="el_inner_width" v-model="cost_info.shipper_payed" disabled>
						<el-button type="success" slot="append">支付记录</el-button>
					</el-input>
				</el-form-item>
			</div>


			<div class="big_tit">本次支付</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="支付金额" style="width:320px">
					<el-input class="el_inner_width" v-model="this_pay_freight" type="number" @change="get_this_pay_info" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="支付类型" style="width:320px">
					<el-select class="el_inner_width" v-model="this_pay_type" @change="get_this_pay_info">
						<el-option label="现金" value="1"></el-option>
						<el-option label="燃油费" value="2"></el-option>
						<el-option label="过路费" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="支付备注" style="width:320px">
					<el-select class="el_inner_width" v-model="mark" allow-create filterable>
						<el-option label="预付款" value="预付款"></el-option>
						<el-option label="到货付" value="到货付"></el-option>
						<el-option label="回单付" value="回单付"></el-option>
						<el-option label="其他" value="其他"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="本次总额" style="width:320px">
					<el-input class="el_inner_width" v-model="this_shipper_need_pay" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="服务费" style="width:320px">
					<el-input class="el_inner_width" v-model="this_service_charge" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="结给收款人" style="width:320px">
					<el-input class="el_inner_width" v-model="this_settled" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="本次开票" style="width:320px">
					<el-input class="el_inner_width" v-model="this_invoiced" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="操作类型" style="width:320px">
					<el-select class="el_inner_width" v-model="this_act_type">
						<el-option label="支付" value="1"></el-option>
						<el-option label="请款" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="支付密码" style="width:320px">
					<el-input class="el_inner_width" v-model="pay_pwd" type="password" :disabled="this_act_type==2" clearable>
						<el-button type="success" @click="reset_pay_pwd_open" slot="append">
							<i class="el-icon-edit"></i>
						</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="bottom_btns">
				<el-button type="primary" @click="sub()">提交</el-button>
			</div>
		</el-form>

		<!-- 重置支付密码 -->
		<el-dialog
			top="3vh" width="500px" 
			title="重置支付密码" 
			append-to-body 
			custom-class="el-dialog-s" 
			:visible.sync="reset_pay_pwd_is_show"
		>
			<payPwdReset @done="reset_pay_pwd_close"></payPwdReset>
		</el-dialog>

	</el-dialog>
</template>

<script>
	import payPwdReset from '@/pages/user/act/pay_pwd.vue'
	export default {
		props:{
			tord_info:Object,
			is_show:Number,
			title:String,
			type:String
		},
		components:{
			payPwdReset
		},
		data() {
			return {

				//页面是否显示
				this_is_show:false,

				//重置支付密码页面是否显示
				reset_pay_pwd_is_show:false,

				//运单编号
				truck_tord_num:'',

				//收款人信息
				truck_owner_info:{},

				//司机信息
				driver_info:{},

				//货车信息
				truck_info:{},

				//已付资金信息
				cost_info:{},

				//剩余待支付金额
				shipper_pay_surplus:0,

				//本次支付类型(1:现金,2:燃油费,3:过路费)
				this_pay_type:'1',

				//本次支付本金
				this_pay_freight:0,

				//本次服务费(this_pay_type==1时有效,其余时间为0)
				this_service_charge:'',

				//本次支付总金额
				this_shipper_need_pay:'',

				//本次开票金额
				this_invoiced:'',

				//本次结给承运人
				this_settled:'',

				//操作类型(1:支付,2:请款)
				this_act_type:'1',

				//支付密码
				pay_pwd:'',

				//备注
				mark:'',
			}
		},
		watch:{
			is_show(new_data){
				if(new_data==0){
					this.this_is_show=false
				}else {
					this.this_is_show=true
					this.init();
				}
			},
		},
		methods: {

			//提交
			sub(){
				if(['1','2','3'].indexOf(this.this_pay_type)==-1){
					this.$my.other.msg({
						type:'warning',
						str:"不存在的支付类型, 请刷新后再试"
					});
					return;
				}


				if(!this.$my.check.is_num(this.this_pay_freight)){
					this.$my.other.msg({
						type:'warning',
						str:"请输入本次支付本金"
					});
					return;
				}
				if(this.this_pay_freight<=0){
					this.$my.other.msg({
						type:'warning',
						str:"本次支付本金不能小等于0"
					});
					return;
				}
				if(this.this_pay_freight>this.shipper_pay_surplus){
					this.$my.other.msg({
						type:'warning',
						str:"本次支付本金不能大于剩余支付金额"
					});
					return;
				}

				if(!this.pay_pwd.trim() && this.this_act_type==1){
					this.$my.other.msg({
						type:'warning',
						str:"缺少支付密码"
					});
					return;
				}
				
				var  pay_action=''
				switch(this.mark){
					case '预付款':pay_action=1;break;
					case '到货付':pay_action=2;break;
					case '回单付':pay_action=3;break;
					case '其他':pay_action=4;break;
				}
				//调接口
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_pay_creat_by_shipper',
						this_act_type:this.this_act_type,
						pay_pwd:this.pay_pwd,
						pay_creat_list:[
							{
								truck_tord_num:this.tord_info.truck_tord_num,
								this_pay_type:this.this_pay_type,
								this_pay_freight:this.this_pay_freight,
								mark:this.mark,
								pay_action,
							}
						]
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:"支付成功"
						});
						this.this_is_show=false;
						this.pay_type='1';
						this.pay_pwd='';
						this.mark='';
						this.$emit("pay_creat_close");
					}
				});
			},

			//页面初始化
			init(){
				this.this_act_type = this.type
				let tord_info=this.tord_info

				if(Object.keys(tord_info).length==0){
					return;
				}

				//运单编号
				this.truck_tord_num="";

				//收款人信息
				this.truck_owner_info=tord_info.truck_owner_info;

				//司机信息
				this.driver_info=tord_info.driver_info;

				//货车信息
				this.truck_info=tord_info.truck_info;

				//剩余待支付金额
				this.shipper_pay_surplus=0;

				//本次支付类型(1:现金,2:燃油费,3:过路费)
				this.this_pay_type='1';

				//本次支付本金
				this.this_pay_freight=null;

				//本次服务费(this_pay_type==1时有效,其余时间为0)
				this.this_service_charge="";

				//本次支付总金额
				this.this_shipper_need_pay="";

				//本次开票金额
				this.this_invoiced="";

				//本次结给收款人
				this.this_settled="";

				//支付密码
				this.pay_pwd="";

				//备注
				switch(tord_info.payed_list.length){
					case 0:this.mark="预付款";break;
					case 1:this.mark="到货付";break;
					case 2:this.mark="回单付";break;
					default :this.mark="其他";break;
				}

				//计算本次支付信息
				this.get_this_pay_info();
			},

			//计算本次支付信息
			get_this_pay_info(){

				if(this.this_pay_freight>this.shipper_pay_surplus){
					this.$my.other.msg({
						type:'warning',
						str:"本次支付金额超过最大可支付金额"
					});
					return;
				}

				//去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'get_tord_pay_info_by_shipper',
						truck_tord_num:this.tord_info.truck_tord_num,
						this_pay_freight:this.this_pay_freight,
						this_pay_type:this.this_pay_type,
					},
					callback:(data)=>{

						//剩余待支付运费
						this.shipper_pay_surplus=data.shipper_pay_surplus

						//支付本金
						this.this_pay_freight=data.this_pay_freight

						//服务费
						this.this_service_charge=data.this_service_charge

						//支付总额
						this.this_shipper_need_pay=data.this_shipper_need_pay

						//本次开票
						this.this_invoiced=data.this_invoiced

						//结给收款人
						if(data.cost_info.calc_mode==1){
							this.this_settled=data.this_settled
						}

						//已付资金信息
						this.cost_info=data.cost_info;
					}
				})
			},

			//打开重置支付密码页面
			reset_pay_pwd_open(){
				this.reset_pay_pwd_is_show=true;
			},
			reset_pay_pwd_close(){//关闭重置支付密码页面
				this.reset_pay_pwd_is_show=false;
			},

			//前往支付密码修改页面
			to_reset_paypwd(){
				this.$router.push({
					path:'/pages/user_info?actContentType=2'
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.bottom_btns{
		text-align: center;
		margin-top: 20px;
		padding-top: 20px;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form_ordinary{
		padding: 10px 0;
		overflow-y:auto;
	}

	.el-dialog-s{
		z-index: 11;
	}

</style>